.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 999;
}

.transparentHeader {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.headerContainer {
  display: flex;
  height: 100%;
  width: 90%;
  box-sizing: border-box;
  box-shadow: none;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 16px;
  box-sizing: border-box;
}

.rightSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 2;
  padding: 16px;
  box-sizing: border-box;
}

.rightSideItems {
  height: 100%;
  width: 100%;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.rightSideItems a {
  text-decoration: none;
  color: white;
}

.rightSideItems a:hover {
  transition: all 0.2s ease-in-out;
  opacity: 0.7;
}

.social {
  display: flex;
  width: 100%;
  gap: 20px;
}

.social img {
  max-width: 25px;
}

.menuIcon {
  color: white;
}

.mobileMenu {
  display: none;
}

.mobileMenuLinkItems {
  text-decoration: none;
  color: var(--bg-main-color);
}

.desktopMenuItem {
  display: flex;
}

@media (max-width: 768px) {
  .logo {
    max-width: 100px;
  }

  .desktopMenuItem {
    display: none;
  }

  .mobileMenu {
    display: flex;
  }

  .social {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 769px) AND (max-width: 1200px) {
  .logo {
    max-width: 160px;
  }
}

@media (min-width: 1201px) {
  .logo {
    max-width: 200px;
  }
}
