.footer {
  background-color: var(--bg-main-color);
  /* height: 3.125rem; */
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: auto;
}

.footerDivider {
  background-color: white;
  height: 4px;
  border: 0;
  border-radius: 4px;
  width: 80%;
}

.contacts {
  padding: 16px 0px;
}

.talkWithUs {
  margin: 16px 0px;
  padding: 2px 0px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.disclaimer {
  /* height: 100px; */
  margin: 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.googleMaps {
  border-radius: 10px;
  margin-top: 16px;
}

.socialMedias {
  display: flex;
  padding: 16px 0px;
  justify-content: space-around;
  width: 35%;
}

@media (min-width: 769px) {
  .contacts {
    display: flex;
    justify-content: space-around;
    width: 60%;
  }

  .socialMedias {
    justify-content: space-around;
    width: auto;
  }
}
