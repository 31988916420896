.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.slide {
  height: calc(100vh - 150px);
  max-width: 100%;
}
.slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.firstCarouselSection {
  background-color: var(--bg-main-color);
}

.smallPresentation {
  color: white;
  font-family: "Alex Brush";
  /* font-family: "Caveat"; */
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 121px;
  background-color: var(--bg-main-color);
}

.smallPresentation div {
  font-family: "Caveat";
}

.smallPresentation h2 {
  font-weight: normal;
}

.secondCarouselSection {
  border-radius: 5px;
  margin: 10px 0px;
}

.secondSlideSection {
  display: flex;
  max-width: 100%;
  margin: 0px 10px;
  margin-left: 5px;
  justify-content: space-between;
  column-gap: 5px;
}
.secondSlideSection img {
  height: 100%;
  width: 50%;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--bg-main-color);
}

.businessValues {
  color: var(--bg-main-color);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.businessValuesDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.environments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
}

.environmentsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 0px;
  max-width: 150px;
}

.environments img {
  max-width: 80px;
}

.quotationButton {
  background-color: var(--bg-main-color) !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 10px !important;
  margin: 16px 0px !important;
}

.quotationButton:hover {
  background-color: var(--bg-main-color) !important;
}

.quotationForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
}

.quotationForm a {
  text-decoration: none;
}

.quotationLines {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;
}

.quotationFields {
  margin: 8px 0px;
}

@media (min-width: 769px) {
  .businessValuesDescription {
    width: 40%;
  }

  .quotationLines {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px;
  }

  .quotationFields {
    margin: 0px 8px;
  }
}

@media (min-width: 900px) {
  .environments {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
    padding: 16px 0px;
  }
}
